import React, {
  useState,
  useEffect,
} from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { I18nRails } from "../../shared/rails-i18n-js";
import LocationReviewResource from "../resources/location-review";
import LocationLayout from "./LocationLayout";
import Card from "@mui/material/Card";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import "react-medium-image-zoom/dist/styles.css";
import { date as dateFilter } from "../filters";
import { makeStyles } from "tss-react/mui";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import withReview from "./hoc/withReview";
import withParent from "./hoc/withParent";
import { isLoggedIn } from "./helpers/utils";
import { useSsrProps } from "../SsrContext";

const styles = (theme) => ({
  root: {
    width: 100,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
});

const AppLocationReviews = ({
  locationId,
  showResourceError,
  toggleLike,
  enableEditing,
  editingFlag,
  setEditingFlag,
  cancelEditingReview,
  basket,
  user,
  initUser,
  fndLocation,
  cartService,
  changeQuantity,
  removeBasketItem,
  numberOfCartItems,
  selectedCountry,
  changeCountry,
  countryConfig,
  setLatLng,
  ...other
}) => {
  const ssrProps = useSsrProps()
  const {Rails = {}, fdLocationsReviews, fdLocationReviews} = ssrProps;

  const [location_reviews, setLocation_reviews] = useState("");
  const [review_title, setReview_title] = useState("Location Reviews");

  const [values, setValues] = useState({
    reviewContent: "",
  });

  const [editingReview, setEditingReview] = useState();
  const [editedReviewContent, setEditedReviewContent] = useState();

  const createReview = (review) => {
    const LocationReview = LocationReviewResource.get(Rails)
    review = new LocationReview({
      location_id: locationId,
      review,
    });
    review.$create(
      (success) => {
        var tempList = [];
        tempList = location_reviews;
        tempList.splice(1, 0, success);
        setLocation_reviews(tempList);
      },
      (error) => {
        showResourceError(error);
      }
    );
  };

  const editReview = () => {
    const LocationReview = LocationReviewResource.get(Rails)
    var review = new LocationReview({
      id: editingReview.id,
      content: editedReviewContent,
      location_id: locationId,
    });
    review.$update(
      (success) => {
        var tempList = [];
        tempList = location_reviews;
        var index = tempList.indexOf(editingReview);
        if (index !== -1) {
          tempList[index] = success;
        }
        setLocation_reviews(tempList);
        setEditingFlag(false);
      },
      (error) => {
        showResourceError(error);
      }
    );
  };

  const initLocationReviews = (cb) => {
    if (!fdLocationReviews) {
      const LocationReview = LocationReviewResource.get(Rails)
      LocationReview.query(
        { location_id: locationId },
        (location_reviews) => {
          setLocation_reviews(location_reviews);
          console.log("location reviews,", location_reviews);
          if (!!cb) {
            return cb(location_reviews);
          }
        }
      );
    } else {
      const LocationReview = LocationReviewResource.get(Rails)
      const location_reviews = JSON.parse(fdLocationsReviews).map(
        (location_review) => new LocationReview(location_review)
      );
      setLocation_reviews(location_reviews);
      if (!!cb) {
        return cb(location_reviews);
      }
    }
  }

  const validateHandler = (values) => {
    const errors = {};

    if (!values.reviewContent) {
      errors.reviewContent = "content required";
    }

    return errors;
  };

  const onSubmit = (values, options) => {
    console.log("values", values);
    createReview({ content: values.reviewContent });
  };

  const useStyles = makeStyles()(styles)
  const {classes, cx} = useStyles();

  const children = function children({
    values,
    errors,
    touched,
    isSubmitting,
    isValidating,
  }) {
    return (
      (<Form method="post" className="login-form">
        <Grid container>
          <Grid size={{xs: 12}}>
            <h5>
              {I18nRails.t("Web.write_a_review")}
              <Field
                name="reviewContent"
                placeholder={I18nRails.t("Web.help_others_review")}
                required
                type="text"
                component="input"
              />
              <ErrorMessage name="reviewContent" component="div" />
            </h5>
          </Grid>
          <Grid size={3}>
            <Button
              disabled={
                Object.keys(errors).length || isSubmitting || isValidating
              }
              type="submit"
              className="clr-dark"
            >
              {I18nRails.t("Web.add_your_review")}
            </Button>
          </Grid>
        </Grid>
      </Form>)
    );
  };

  useEffect(() => {
    initLocationReviews();
  }, []);

  return (
    <LocationLayout
      user={user}
      initUser={initUser}
      fndLocation={fndLocation}
      basket={basket}
      cartService={cartService}
      changeQuantity={changeQuantity}
      removeBasketItem={removeBasketItem}
      toggleLike={toggleLike}
      numberOfCartItems={numberOfCartItems}
      selectedCountry={selectedCountry}
      changeCountry={changeCountry}
      countryConfig={countryConfig}
      setLatLng={setLatLng}
      {...other}
    >
      <div className="tabular-content">
        {isLoggedIn(Rails) ? (
          <>
            {location_reviews.length < 1 ? (
              <main className="pad-right">
                {I18nRails.t("Web.be_the_first_review")}
              </main>
            ) : null}
            <Formik
              initialValues={values}
              validate={validateHandler}
              onSubmit={onSubmit}
            >
              {(formikProps) => children(formikProps)}
            </Formik>
          </>
        ) : (
          <main className="pad-right">
            {I18nRails.t("venue.alertmessage.togglereviewalert")}
          </main>
        )}
        {location_reviews.length > 0 && (
          <main className="pad-right">
            <h5>{review_title}</h5>
            {location_reviews.map((location_review) => (
              <List
                className="reviews-list"
                key={`location-review-${location_review.id}`}
              >
                <ListItem>
                  <Card elevation={3} style={{ width: "100%" }}>
                    <Grid container style={{ padding: "0.25%" }}>
                      {!!location_review.user && (
                        <>
                          <Grid
                            style={{
                              paddingRight: "0",
                              paddingLeft: "1%",
                              paddingTop: "1%",
                            }}
                            size={{
                              xs: 2,
                              sm: 1
                            }}>
                            <Avatar
                              src={location_review.user.user_image_url}
                              style={{
                                height: "60px !important",
                                width: "60px !important",
                              }}
                            >
                              {location_review.user.first_name.charAt(0)}{" "}
                              {location_review.user.last_name.charAt(0)}
                            </Avatar>
                          </Grid>
                          <Grid
                            style={{ paddingLeft: "1%", paddingTop: "1%" }}
                            size={{
                              xs: location_review.user ? 8 : 12,
                              sm: location_review.user ? 9 : 12
                            }}>
                            <span
                              style={{ fontWeight: "bold", marginTop: "1%" }}
                            >
                              {location_review.user &&
                                `${location_review.user.first_name} ${location_review.user.last_name}`}{" "}
                            </span>
                            {editingReview?.id === location_review?.id &&
                            editingFlag ? (
                              <Grid container>
                                <Grid
                                  size={{
                                    xs: 7,
                                    sm: 7
                                  }}>
                                  <form noValidate autoComplete="off">
                                    <TextField
                                      id="standard-basic"
                                      label="Edit Review"
                                      value={editedReviewContent}
                                      onChange={(e) =>
                                        setEditedReviewContent(e.target.value)
                                      }
                                    />
                                  </form>
                                </Grid>
                                <Grid
                                  size={{
                                    xs: 5,
                                    sm: 5
                                  }}>
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    style={{
                                      paddingTop: "5%",
                                      paddingBottom: "5%",
                                    }}
                                    onClick={(e) => editReview()}
                                  >
                                    Save Review
                                  </Button>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    style={{
                                      paddingTop: "5%",
                                      paddingBottom: "5%",
                                      marginLeft: "5%",
                                    }}
                                    onClick={(e) =>
                                      cancelEditingReview.current()
                                    }
                                  >
                                    Cancel
                                  </Button>
                                </Grid>
                              </Grid>
                            ) : (
                              <p
                                style={{
                                  marginTop: "-8px",
                                  marginBottom: "3%",
                                }}
                              >
                                &quot;{location_review.content}&quot;
                                <span
                                  style={{
                                    fontSize: "10px",
                                    color: "grey",
                                    fontStyle: "italic",
                                  }}
                                >
                                  - at {dateFilter(location_review.created_at)}
                                </span>
                              </p>
                            )}
                          </Grid>
                        </>
                      )}
                      <Grid
                        style={{ paddingRight: "1%" }}
                        size={{
                          xs: 2,
                          sm: 2
                        }}>
                        {isLoggedIn(Rails) ? (
                          <a
                            className="right"
                            onClick={(e) =>
                              toggleLike.current(location_review, "Review", e)
                            }
                            style={{ padding: "4px" }}
                          >
                            <i
                              className={
                                "fas fa-heart " +
                                (isUserLiked(user, location_review, "Review")
                                  ? "red-text"
                                  : "")
                              }
                            ></i>
                            {location_review.favorites_count > 0
                              ? location_review.favorites_count
                              : ""}
                          </a>
                        ) : (
                          <a
                            className="right"
                            onClick={(e) =>
                              toggleLike.current(location_review, "Review", e)
                            }
                          >
                            <i className="fas fa-heart"></i>
                            {location_review.favorites_count > 0
                              ? location_review.favorites_count
                              : ""}
                          </a>
                        )}
                        {location_review.user?.id == user?.id && !editingFlag && (
                          <a
                            className="right"
                            onClick={(e) =>
                              enableEditing0.current(location_review, e)
                            }
                            style={{ padding: "4px" }}
                          >
                            <i className="fas fa-pencil-alt pencil-hover"></i>
                          </a>
                        )}
                      </Grid>
                    </Grid>
                  </Card>
                </ListItem>
              </List>
            ))}
          </main>
        )}
      </div>
    </LocationLayout>
  );
};

export default withParent(withReview(AppLocationReviews));
