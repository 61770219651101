import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router";
import AppLayout from "./AppLayout";
import ShowIndexCart from "./ShowIndexCart";

import { I18nRails } from "../../shared/rails-i18n-js";

import * as rh from "./helpers/routes";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import { Helmet } from "react-helmet";
import { loadImage } from "./helpers/utils";
import withParent from "./hoc/withParent";
import withCart from "./hoc/withCart";
import { location_header } from "../models/location";
import withOrder from "./hoc/withOrder";


const AppCart = ({
  Rails,
  increaseQuantity,
  decreaseQuantity,
  user,
  clearCart,
  fndLocation,
  baskets,
  ...other
}) => {
  const [expanded, setExpanded] = useState(true);

  const handleChange = (e) => {
    e.preventDefault();
    setExpanded(!expanded);
  };

  const getMetaTags = () => {
    let seo_description = "Food Cart Deal of your chosen orders. ";
    let seo_keywords = "Food Cart, Your Orders";
    let seo_title = "Food Cart - Your Orders | Foodnerd";
    return (
      <Helmet >
        <title>
          {seo_title}
        </title>
        <meta name="description" content={seo_description} />
        <meta name="keywords" content={seo_keywords} />
        <meta name="title" content={seo_title} />
      </Helmet>
    );
  };

  useEffect(() => {
      window.addEventListener("load", () => {
        let element = document.getElementById("hubspot-messages-iframe-container");
        if (element) element.style.height = "100px";
      });
  }, []);

  var metaTags = getMetaTags();
  const backgroundImage =
    !!fndLocation && !!location_header(fndLocation)
      ? location_header(fndLocation)
      : loadImage("orderNowBackground.jpg");

  return <>
    {metaTags}
    <AppLayout user={user} Rails={Rails} {...other}>
      <Grid size={{xs: 12}} style={{ height: '95vh', marginTop: '5%', backgroundImage: 'url(' + backgroundImage + ')' }}>
          <Grid size={{xs: 12, lg: 12, md: 12}} style={{marginTop: '25px'}}>
            {baskets.length < 1 ? (
              <p className="center empty-cart white-text">
                <b>{I18nRails.t('client.basket.your_basket_is_empty')}</b><br />
                <b><Link style={{color: 'red'}} to={rh.show_locations()}>{I18nRails.t('client.basket.go_back_menus')}</Link></b>
              </p>
            ) : (
              <List style={{width: '100%'}}>
                {baskets.map((basket, index) => (
                  !!basket.items && basket.items.length > 0 && (
                    <ListItem style={{width: '100%'}} key={`hom${index}`} className={baskets.length > 1 ? '' : 'active'}>
                      <Accordion style={{marginBottom: '2%', backgroundColor: '#fff', width: '100%'}} expanded={expanded} onChange={handleChange}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{width: '100%', backgroundColor: '#F5F5F5'}} aria-controls="panel1a-content" id="panel1a-header">
                          <i className="material-icons" style={{display: 'flex', alignItems: 'center', marginRight: '10px'}}>shopping_basket</i>
                          <Link to={rh.show_location({location_id: basket.location_id})}>{basket.location.name}</Link>
                        </AccordionSummary>
                        <AccordionDetails style={{padding: '0', backgroundColor: '#fff', width: '100%'}}>
                          <ShowIndexCart basket={basket} style={{width: '100%'}} Rails={Rails} increaseQuantity={increaseQuantity.current} decreaseQuantity={decreaseQuantity.current} clearCart={clearCart} />
                        </AccordionDetails>
                      </Accordion>
                    </ListItem>
                  )
                ))}
              </List>
            )}
          </Grid>
        </Grid>
    </AppLayout>
  </>;
};

export default withParent(withOrder(withCart(AppCart)));
