import React, { useState, useEffect, useRef } from "react";
import Alert from "@mui/material/Alert";
import Grid from '@mui/material/Grid';

import CartService from "../services/CartService";

import AppHeader from "./Headers/AppHeader";
import AppSideNav from "./AppSideNav";
import AppFooter from "./Footers/AppFooter";
import { useSsrProps } from "../SsrContext";

const AppLayout = ({
  basket,
  user,
  selectedCountry,
  changeCountry,
  countryConfig,
  children,
  ...others
}) => {
  const ssrProps = useSsrProps()
  const {Rails} = ssrProps;

  const [isSideNavOpened, setIsSideNavOpened] = useState(false);
  const [hideFlash, setHideFlash] = useState({});
  const [flashTime, setFlashTime] = useState();

  const cartServiceRef = useRef(CartService.get(Rails));

  useEffect(() => {
    if (!!Rails.flashTime) {
      if ((typeof window !== 'undefined') && ("localStorage" in window)) {
        let flashTime = window.localStorage.getItem('flashTime');
        if (!flashTime || flashTime !== Rails.flashTime) {
          setFlashTime(Rails.flashTime)
        }
        window.localStorage.setItem('flashTime', Rails.flashTime);
      }
    }
  })

  const handleAppSideNav = () => {
    setIsSideNavOpened(!isSideNavOpened);
  };

  const onFlashClose = (msg_type_index) => {
    let updatedHideFlash = {...hideFlash}
    updatedHideFlash[msg_type_index] = true;
    setHideFlash(updatedHideFlash);
  };

  const numberOfCartItems = (!!basket && !!basket.totals) ? basket.totals.numberOfCartItems : cartServiceRef.current.numberOfItems();
  const { flashes: flashes } = Rails;
  return <>
    <AppHeader 
      countryConfig={countryConfig}
      basket={basket}
      user={user}
      handleAppSideNav={handleAppSideNav}
      numberOfCartItems={numberOfCartItems}
    />
    <AppSideNav 
      Rails={Rails}
      numberOfCartItems={numberOfCartItems} 
      handleAppSideNav={handleAppSideNav} 
      isSideNavOpened={isSideNavOpened} 
      countryConfig={countryConfig}
      selectedCountry={selectedCountry}
      changeCountry={changeCountry}
      user={user} 
      {...others} 
    />
    <Grid container>
      {children}
      <Grid size={{xs: 12}}>
        {!!flashes && Rails.flashTime === flashTime && Object.keys(flashes).map((msg_type) => {
          const messages = flashes[msg_type];
          return messages.map((message, index) => {
            if (!hideFlash[msg_type + index]) {
              return <>
                <br />
                <Alert severity={msg_type} onClose={() => { onFlashClose(msg_type + index) }}>{message}</Alert>
              </>;
            } else {
              return null;
            }
          });
        })}
      </Grid>
      <Grid size={{xs: 12}}>
        <AppFooter 
          Rails={Rails}
          // fndLocation={}
          basket={basket}
          numberOfCartItems={numberOfCartItems}
          countryConfig={countryConfig}              
        />
      </Grid>
    </Grid>
  </>;
};

export default AppLayout;
