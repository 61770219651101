import React, { useState, useRef, useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router";
import { I18nRails } from "../../shared/rails-i18n-js";
import * as rh from "./helpers/routes";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import ClickAwayListener from "@mui/material/ClickAwayListener";
import { Drawer, Divider, IconButton } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { loadImage } from "./helpers/utils";
import { isUserManager } from "../models/user";
import withParent from "./hoc/withParent";
import { useSsrProps } from "../SsrContext";

const styles = {
  sideNav: {
    marginTop: "-60px",
    zIndex: 3,
    marginLeft: "0px",
    position: "fixed",
  },
  link: {
    color: "black",
    textDecoration: "none",
  },
};

const AppSideNav = ({
  user,
  offers,
  basket,
  numberOfCartItems,
  handleAppSideNav,
  isSideNavOpened,
  initOffers,
  countryConfig,
  logout
}) => {

  const ssrProps = useSsrProps()
  const {Rails} = ssrProps;

  const [openDropdown, setOpenDropdown] = useState(false);
  const [anchorEl, setAnchorEl] = useState();
  const [locale, setLocale] = useState();

  const navigate = useNavigate();

  const selected_country_icon = () => {
    var selected_country = "pk";
    const _locale = localStorage.getItem("locale");

    if (_locale == "en") {
      selected_country = "us";
    } else if (_locale == "ar") {
      selected_country = "sa";
    } else if (_locale == "fr") {
      selected_country = "fr";
    }

    return country_icon(selected_country);
  };

  const country_icon = (country_alpha) => {
    return `flag-icon-${country_alpha.toLowerCase()}`;
  };

  const handleClick = (event) => {
    console.log("event", event);
    setOpenDropdown(!openDropdown);
    setAnchorEl(event.currentTarget);
  };

  const handleClickAway = () => {
    setOpenDropdown(false);
  };

  const closeDrawer = () => {
    handleAppSideNav();
  };

  const countryAlphas = Rails.country_alphas;

  useEffect(() => {
    initOffers();
  }, []);

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      right: 14,
      top: 2,
      border: `2px solid white`,
      padding: "0 4px",
    },
  }));

  const loggedIn = !!user;
  
  return (
    
    (<Drawer variant='temporary' open={isSideNavOpened} onClose={closeDrawer}>
      <div className='image-tab'>
        <div className='user-view'>
          <div className='background'>
            <img alt='' src={loadImage('Loc2.jpg')} />
          </div>
          {loggedIn && !!user.avatar && !!userAvatar(user) && (
            <>
              <Link to='/users/me'>
                <img className='circle' src={userAvatar(user)} alt='' />
              </Link>
              <br />
              <Link to='/users/me' style={{ paddingBottom: '3%', paddingTop: '2%' }}>
                <span className='white-text' style={{ color: 'white' }}>
                  {user.first_name} {user.last_name}
                </span>
              </Link>
            </>
          )}
        </div>
      </div>
      <List className='sidenav' id='slide-out' style={{ transform: 'translateX(0%)' }}>
        <ListItem className='side-list'>
          {offers && offers.length > 0 && (
            <div className='slider'>
              <List className="slides">
                {offers.map((offer) => {
                  if (!!offer.show_side && !!offer.side_bar_image_url) {
                    if (offer.is_general_offer()) {
                      return (
                        <ListItem onClick={(e) => navigate('/locations')}>
                          <Link to="/locations">
                            <img alt="" src={offer.side_bar_image_url} />
                          </Link>
                        </ListItem>
                      );
                    } else {
                      return (
                        <ListItem onClick={(e) => navigate('/locations/'+offer.discountable_slug)}>
                          <Link to={'/locations/'+offer.discountable_slug}>
                            <img alt="" src={offer.side_bar_image_url} />
                          </Link>
                        </ListItem>
                      );
                    }
                  }
                })}
              </List>
            </div>
          )}
        </ListItem>
        {loggedIn && (
          <>
            {user && user.managed_location_ids && user.managed_location_ids.length > 0 && (
              <ListItem
                className='side-list'
                onClick={(e) => navigate('/manager/locations')}
                style={{ cursor: 'pointer' }}
              >
                <NavLink to='/manager/locations' activeClassName='active click-area'>
                  <i className='fas fa-tasks' style={{ paddingRight: '20px' }}></i>
                  <span style={{ textTransform: 'capitalize' }}>
                    {I18nRails.t('side_nav.manager')}
                  </span>
                </NavLink>
              </ListItem>
            )}
            <ListItem
              className='side-list'
              onClick={(e) => navigate('/addresses')}
              style={{ cursor: 'pointer' }}
            >
              <NavLink to='/addresses' activeClassName='active click-area'>
                <i className='fas fa-user' style={{ paddingRight: '20px' }}></i>
                <span style={{ textTransform: 'capitalize' }}>
                  {I18nRails.t('Web.addresses')}
                </span>
              </NavLink>
            </ListItem>
          </>
        )}
        <ListItem className="side-list" onClick={(e) => navigate('/blogs')} style={{ cursor: 'pointer' }}>
          <NavLink to='/blogs' activeClassName="active click-area">
            <i className="fas fa-rss" style={{ paddingRight: '20px' }}></i>
            <span style={{ textTransform: 'capitalize' }}>
              {I18nRails.t("business_dashboard.footer.blog")}
            </span>
            <div className="clearfix"></div>
          </NavLink>
        </ListItem>
        {loggedIn && (
          <ListItem className="side-list" onClick={(e) => navigate('/favorites')} style={{ cursor:'pointer' }}>
            <NavLink to='/favorites' activeClassName="active click-area">
              <i className="fas fa-heart" style={{ paddingRight: '20px' }}></i>
              <span style={{ textTransform: 'capitalize' }}>
                {I18nRails.t("Web.favorites")}
              </span>
              <div className="clearfix"></div>
            </NavLink>
          </ListItem>
        )}
        <ListItem className="side-list" onClick={(e) => navigate('/locations')} style={{ cursor:'pointer' }}>
          <NavLink to='/locations' activeClassName="active click-area">
            <i className="fas fa-utensils" style={{ paddingLeft: '0', paddingRight: '20px' }}></i>
            <span style={{ textTransform: 'capitalize' }}>
              {I18nRails.t("Web.locations")}
            </span>
            <div className="clearfix"></div>
          </NavLink>
        </ListItem>
        {loggedIn && (
          <ListItem className="side-list" onClick={(e) => navigate('/orders')} style={{ cursor:'pointer' }}>
            <NavLink to="/orders" activeClassName="active click-area">
              <i className="fas fa-shopping-bag" style={{ paddingRight: '20px' }}></i>
              <span style={{ textTransform: 'capitalize' }}>
                {I18nRails.t("Web.ordering.orders")}
              </span>
              <div className="clearfix"></div>
            </NavLink>
          </ListItem>
        )}
        { !!basket && !!basket.location_id ?
          (<ListItem className="side-list" onClick={(e) => navigate('/locations/'+basket.location_id+'/cart')} style={{cursor: 'pointer'}}>
            <NavLink to={"/locations/"+basket.location_id+"/cart"} activeClassName="active click-area" style={{'cursor': 'pointer'}}>
              <StyledBadge badgeContent={basket.totals.numberOfCartItems} color="error">
                <i className="fas fa-cart-arrow-down" style={{paddingRight: '20px'}} />
              </StyledBadge>
              <span>{I18nRails.t("Web.shopping.cart")}</span>
            </NavLink>
          </ListItem>)
          :
          (<ListItem className="side-list" onClick={(e) => navigate('/cart')} style={{cursor: 'pointer'}}>
            <NavLink to="/cart" activeClassName="active click-area" style={{cursor: 'pointer'}}>
              <StyledBadge badgeContent={numberOfCartItems} color="error">
                <i className="fas fa-shopping-cart" style={{paddingRight: '20px'}} />
              </StyledBadge>
              <span style={{textTransform: 'capitalize'}}>{I18nRails.t("Web.shopping.cart")}</span>
            </NavLink>
          </ListItem>)
        }
        {loggedIn ? (
          <>
            {user.manager && (
              <>
                <ListItem
                  className="side-list"
                  onClick={(e) => navigate('/manager/locations')}
                  style={{ cursor: 'pointer' }}
                >
                  <NavLink to='/manager/locations' activeClassName="active click-area">
                    <i className="fas fa-user-plus" style={{ paddingRight: '20px' }}></i>
                    <span style={{ textTransform: 'capitalize' }}>Manager Side</span>
                    <div className="clearfix"></div>
                  </NavLink>
                </ListItem>
                <ListItem
                  className="side-list"
                  onClick={(e) => navigate('/manager/pos')}
                  style={{ cursor: 'pointer' }}
                >
                  <NavLink to='/manager/pos' activeClassName="active click-area">
                    <i className="fas fa-cc" style={{ paddingRight: '20px' }}></i>
                    <span style={{ textTransform: 'capitalize' }}>Venues</span>
                    <div className="clearfix"></div>
                  </NavLink>
                </ListItem>
              </>
            )}
            <ListItem
              className="side-list"
              onClick={(e) => navigate('/users/me')}
              style={{ cursor: 'pointer' }}
            >
              <NavLink to='/users/me' activeClassName="active click-area">
                <i className="fas fa-user" style={{ paddingRight: '20px' }}></i>
                <span style={{ textTransform: 'capitalize' }}>
                  {I18nRails.t("Web.profile")}
                </span>
              </NavLink>
            </ListItem>
          </>
        ) : (
          <ListItem className="side-list" onClick={(e) => navigate('/login')} style={{ cursor: 'pointer' }}>
            <NavLink to='/login' activeClassName="active click-area">
              <i className="fas fa-user" style={{ paddingRight: '20px' }}></i>
              <span style={{ textTransform: 'capitalize' }}>{I18nRails.t("side_nav.login")}</span>
            </NavLink>
          </ListItem>
        )}
        {loggedIn && (
          <>
            {isUserManager(user, location) && (
              <ListItem
                className="side-list"
                onClick={(e) =>
                  navigate(rh.location_new_blog({ location_id: location.slug }))
                }
              >
                <NavLink
                  style={{ cursor: "pointer" }}
                  to={rh.location_new_blog({ location_id: location.slug })}
                  activeClassName="active click-area"
                >
                  Create New Blog
                </NavLink>
              </ListItem>
            )}
            {isUserManager(user, location) && (
              <ListItem
                className="side-list"
                onClick={(e) =>
                  navigate(rh.location_new_page({ location_id: location.slug }))
                }
              >
                <NavLink
                  style={{ cursor: "pointer" }}
                  to={rh.location_new_page({ location_id: location.slug })}
                  activeClassName="active click-area"
                >
                  Create New Page
                </NavLink>
              </ListItem>
            )}
            <ListItem className="side-list" onClick={logout} style={{ cursor: 'pointer' }}>
              <a>
                <i className="fas fa-sign-out" style={{ paddingRight: '20px' }} />
                <span style={{ textTransform: 'capitalize' }}>
                  {I18nRails.t('side_nav.logout')}
                </span>
              </a>
            </ListItem>
            <ListItem className="side-list">
              <Accordion
                style={{
                  width: '80%',
                  marginBottom: '1%',
                  backgroundColor: '#fff',
                  padding: '0 0',
                  boxShadow: '0',
                }}
              >
                <AccordionSummary
                  style={{ margin: '0 !important' }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <i
                    className="fas fa-info-circle"
                    style={{
                      margin: '0 16px 0 0',
                      lineHeight: '48px',
                      height: '48px',
                      float: 'left',
                      width: '24px',
                      color: 'rgba(0, 0, 0, 0.54)',
                    }}
                  />
                  <p>{I18nRails.t('Web.help')}</p>
                </AccordionSummary>
                <AccordionDetails
                  style={{ padding: '0', backgroundColor: '#fff', width: '100%' }}
                >
                  <Grid container>
                    <Grid style={{ fontSize: '13px' }} size={12}>
                      <a href={"tel:"+countryConfig.telto} className="waves-effect">
                        <i className="fas fa-phone phone-call" style={{ marginRight: '5px' }} />
                        {countryConfig.telto}
                      </a>
                    </Grid>
                    <Grid style={{ fontSize: '13px' }} size={12}>
                      <a href={"mailto:"+countryConfig.email} className="waves-effect">
                        <i className="fas fa-envelope" style={{ marginRight: '5px' }} />
                        {countryConfig.email}
                      </a>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </ListItem>
          </>
        )}
        <ListItem className="side-list">
          <ClickAwayListener onClickAway={handleClickAway}>
            <Button variant="contained" onClick={handleClick} style={{ backgroundColor: '#F55433', color: 'white', width: '80%', marginRight: '5%', paddingTop: '5%', paddingBottom: '5%' , display:'none' }}>
              <span className={'flag-icon '+selected_country_icon()} />
            </Button>
          </ClickAwayListener>
          <Menu variant="menu" anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} transformOrigin={{ vertical: 'top', horizontal: 'center' }} anchorEl={anchorEl} style={{ textAlign: 'right' }} open={openDropdown}>
            <Grid container>
              {countryAlphas.map((country_alpha, idx) => (
                <Grid key={idx} size={12}>
                  <MenuItem style={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => change_country(country_alpha)}>
                    <a>
                      <span className={'flag-icon '+country_icon(country_alpha)} />
                    </a>
                  </MenuItem>
                </Grid>
              ))}
            </Grid>
          </Menu>
        </ListItem>
      </List>
    </Drawer>
    )
  );
};

export default AppSideNav;
