import React from "react";
// import { Link } from "react-router";
import { I18nRails } from "../../../shared/rails-i18n-js";
import {price as priceFilter, showPosition as showPositionFilter} from "../../filters";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { isLoggedIn } from "../helpers/utils";
import { menuItemHasRequiredOptions } from "../../models/menu_item";
import { isUserLiked } from "../../models/user";

const template = function({
  Rails,
  user,
  fndLocation,
  menu_item,
  menu,
  index_2,
  cindex,
  openMenuItemModal,
  openReviewModal,
  toggleLike,
  class_for_option_value,
  addToBasketDirect,
}) {
  return (<>
    {menu_item.type !== "MenuItemDiv" && menu_item.status !== 'hidden' && (
      <Box border={1} borderColor="#e0e0e0" style={{ backgroundColor: 'white' }}>
        <Grid container spacing={0} style={{ padding: '15px' }}>
          {!!menu_item.url_image1 && (
            <Grid style={{ padding: '5px' }} size={1}>
              <img src={menu_item.url_image1} style={{ height: '45px', width: 'auto' }} className='responsive-img' />
            </Grid>
          )}
          {!menu_item.url_image1 && (
            <Grid size={1}>
              <h1>{menu}</h1>
            </Grid>
          )}
          <Grid style={{ padding: '10px' }} size={6}>
            <span
              className="title"
              onClick={(e) => openMenuItemModal(menu_item, menu)}
              style={{ cursor: 'pointer' }}
            >
              {showPositionFilter(menu_item.order_position, menu.show_position, menu_item.position)}
              {menu_item.title}
            </span>
            <p className="dish-description truncate">{menu_item.description}</p>
            <div className="reviewstats">
              <a
                className="comments-menu-item left"
                onClick={(e) => openReviewModal('MenuItem', menu_item, e)}
              >
                <i className="fas fa-comment left yellow-cmnt" aria-hidden="true"></i>
                {/* { menu_item.reviews_count > 0 ? menu_item.reviews_count : "" } */}
              </a>
              {isLoggedIn(Rails) ? (
                <a
                  className="likes-menu-items left"
                  onClick={(e) => toggleLike(menu_item, 'MenuItem', e)}
                >
                  <i
                    className={`fas fa-heart ${user && isUserLiked(user, menu_item,'MenuItem') ? 'red-text' : ''}`}
                  ></i>
                  {/* { menu_item.favorites_count > 0 ? menu_item.favorites_count : "" } */}
                </a>
              ) : (
                <a className="likes-menu-items left" onClick={(e) => toggleLike(menu_item, 'MenuItem', e)}>
                  <i className="fas fa-heart"></i>
                  {/* { menu_item.favorites_count > 0 ? menu_item.favorites_count : "" } */}
                </a>
              )}
            </div>
          </Grid>
          <Grid style={{textAlign:'right'}} size={5}>
            <Grid container>
              {!menuItemHasRequiredOptions(menu_item) &&
                <Grid size={12}>
                  {!!menu_item.price ?
                    <p className="price priceSingle-value right">
                      {priceFilter(menu_item.price, true, fndLocation.currency_symbol)}
                    </p>
                    :
                    !!menu_item.component_option_values && !!menu_item.component_option_values[0] &&
                    <p className="price priceSingle-value right">
                      {priceFilter(menu_item.component_option_values[0].available_option_values[0].price, true, fndLocation.currency_symbol)}
                    </p>
                  }
                </Grid>
              }
              {menuItemHasRequiredOptions(menu_item) &&
                <Grid size={12}>
                  {menuItemHasRequiredOptions(menu_item) &&
                    !!menu_item.component_option_values &&
                    menu_item.component_option_values.map((component_option_value, index_3) => {
                      if (component_option_value) {
                        return (
                          <div className="price right" key={index_3 + "cov-" + cindex}>
                            {component_option_value.available_option_values.map((available_option_value, index_4) => {
                              return (
                                <div
                                  className={'price-display'+class_for_option_value(
                                    available_option_value,
                                    menu_item
                                  )}
                                  key={index_4 + "ov-" + cindex}
                                  type="option"
                                >
                                  <span className="price-value">
                                    {priceFilter(
                                      available_option_value.price,
                                      true,
                                      fndLocation.currency_symbol
                                    )}
                                  </span>
                                  <span className="price-name">{available_option_value.name}</span>
                                </div>
                              );
                            })}
                          </div>
                        );
                      }
                    })}
                </Grid>
              }
            </Grid>
            <Grid container>
              <Grid size={12}>
                {!menuItemHasRequiredOptions(menu_item) ?
                  <p className="a right order-btn" event='click' onClick={(e) => addToBasketDirect(menu_item, menu)} style={{'cursor':'pointer'}}>
                    {I18nRails.t("client.order_action.create")}
                  </p>
                  :
                  <p className="a right order-btn" event='click' href='#menu-item-modal' onClick={(e) => openMenuItemModal(menu_item, menu, e)} style={{'cursor':'pointer'}}>
                    {I18nRails.t("client.order_action.choose")}
                  </p>
                }
              </Grid>
            </Grid>
          </Grid>            
        </Grid>
      </Box>
    )}
  </>);  
};

export default template;
