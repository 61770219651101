import React, { useRef, useEffect } from "react";
import { I18nRails } from "../../shared/rails-i18n-js";
import ShowOrders from "./ShowOrders";
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

const InfiniteOrders = ({
  loadMoreOrders,
  items,
  loading,
  hasMore,
  fdUser,
  ...others
}) => {

  const footer_height = useRef(undefined);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.scrollTo(0, 0);
      return () => {
        document.removeEventListener("scroll", onDocumentScroll);
      }
    }
  }, []);

  const onDocumentScroll = (e) => {
    const documentElement = document.documentElement;
    const scrollTop =
      (window.pageYOffset || documentElement.scrollTop) -
      (documentElement.clientTop || 0);

    let condition =
      scrollTop + documentElement.clientHeight + footer_height.current >=
      documentElement.scrollHeight;
    condition = condition && !loading;

    if (condition && hasMore) {
      loadMoreOrders();
    }
  };

  const fdOrders = items.map((order) => order[0]);
  console.log("fdUser", fdUser);
  return (
    (<Grid size={12}>
      <Container>
        { fdUser ? (
          loading ? (
            <CircularProgress style={{marginTop:'8%'}}/>
          ) : (
            items.length < 1 ? (
              <Card style={{backgroundColor:'#f45f5f',marginTop:'7%'}} elevation={3}>
                <p style={{paddingLeft:'2%'}}>
                  <b>
                    { I18nRails.t('client.recents.no_orders') }
                  </b>
                </p>
              </Card>
            ) : (
              <ShowOrders style={{marginTop:'10%'}} orders={fdOrders} {...others}/>
            )
          )
        ) : (
          <p>Login to your existing account to view recent orders or Sign Up to place new order</p>
        )}          
      </Container>
    </Grid>)
  );
};

export default InfiniteOrders;
