import React, {
  useState,
  useRef,
  useEffect,
} from "react";
import { Link, useLocation, useNavigate } from "react-router";
import * as Sentry from "@sentry/browser";
import { I18nRails } from "../../shared/rails-i18n-js";

import UserOrderResource from "../resources/user-order";
import DeliveriesResource from "../resources/deliveries";
import OnTimesParser from "../../shared/on-times-parser";
import CartService from "../services/CartService";
import CheckoutSteps from "./templates/CheckoutSteps";
import Container from "@mui/material/Container";
import axios from "axios";
import Grid from "@mui/material/Grid";

import sweetalert2 from "sweetalert2";
import * as rh from "./helpers/routes";
import AppLayout from "./AppLayout";
import UserResource from "../resources/user";
import AuthFactory from "../services/AuthFactory";
import AccessTokenFactory from "../services/AccessTokenFactory";
import { t } from "i18n-js";
import QueryString from "query-string";
import { withLocation } from "../../react-pos/components/withLocation";
import { withParams } from "../../react-pos/components/withParams";

import { withNavigate } from "../../react-pos/components/withNavigate";
import LocationResource from "../resources/location";
import {
  isLocationOpenNow,
  locationTimeToday,
  location_address,
  location_future_orders_activated,
  location_main_image_url,
} from "../models/location";
import { resetBasketTotals } from "./Model/basket";
import withParent from "./hoc/withParent";
import withCart from "./hoc/withCart";
import { isLoggedIn } from "./helpers/utils";
import withOrder from "./hoc/withOrder";

const AppLocationCart = ({
  Rails,
  basket,
  user,
  order,
  setOrder,
  cartService,
  setBasket,
  loadLocation,
  showResourceResponseError,
  setBasketField,
  verifyVoucherCode,
  changeQuantity,
  confirmPhoneNumber,
  clearCart,
  fndLocation,
  setUserPhone,
  loadUser,
  setCheckoutStarted,
  checkoutStarted,
  ...other
}) => {
  const navigate = useNavigate();
  const domLocation = useLocation();

  const [isOrderAsap, setIsOrderAsap] = useState(false);
  const [orderProcessing, setOrderProcessing] = useState(false);
  const [dayAhead, setDayAhead] = useState("");
  const [deliveryTime, setDeliveryTime] = useState("");
  const [deliveryTimes, setDeliveryTimes] = useState([{ time: new Date() }]);
  const [availableDays, setAvailableDays] = useState([]);
  const [channel, setChannel] = useState("");
  const [selectedAddress, setSelectedAddress] = useState("");
  const [deliveryResolved, setDeliveryResolved] = useState(false);
  const [pinCode, setPinCode] = useState("");
  const [phone_number, setPhone_number] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [radioValue, setRadioValue] = useState("");
  const [thiscustomer, setThiscustomer] = useState(null);
  const [thisCutomerCredentials, setThisCutomerCredentials] = useState(null);
  const [enableGuestCheckout, setEnableGuestCheckout] = useState(false);
  const [userInputVerification, setUserInputVerification] = useState("");
  const [openAddressDialog, setOpenAddressDialog] = useState(false);
  const [openGuestRegisterPasswordDialog, setOpenGuestRegisterPasswordDialog] =
    useState(false);
  const [showVerification, setShowVerification] = useState(false);
  const [showCustomerAddress, setShowCustomerAddress] = useState(true);
  const [showCreatedUserAlert, setShowCreatedUserAlert] = useState(false);
  const [invalidVerificationCodeError, setInvalidVerificationCodeError] =
    useState(false);
  const [checkoutModeSelected, setCheckoutModeSelected] = useState(false);
  const [paymentOptionsSelected, setPaymentOptionsSelected] = useState(false);
  const [order_state, setOrder_state] = useState();
  const [delivery, setDelivery] = useState();
  const [location0, setLocation] = useState();
  const [paymentSelected, setPaymentSelected] = useState();
  const [customer_phone, setCustomer_phone] = useState();
  const [pinCodeValid, setPinCodeValid] = useState();
  const [location_id, setLocation_id] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [addressError, setAddressError] = useState("");

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  function addDeliveryCharges(order, basket, delivery) {
    const delivery_price = parseFloat(delivery.delivery_price);
    basket.totals.delivery_charges = delivery_price;
    resetBasketTotals(basket, cartService);
    const { order_items } = order;
    for (let order_item of basket.items) {
      if (order_item.type === "OrderItemDelivery" && channel == "delivery") {
        order_item.price = delivery_price;
        order_item.item_id = delivery.id;
        return;
      }
    }
    //else
    order_items.push({
      type: "OrderItemDelivery",
      item_type: "Delivery",
      item_id: delivery.id,
      price: delivery_price,
      number: 1,
    });
  }

  const generateOrder =(
    basket,
    user_token,
    places_payment_method,
    channel,
    delivery,
    address
  ) => {
    let message, order;
    const order_items_array = [];
    const menu_item_ingredient_array = [];
    const menu_item_ingredient = [];
    let total_price = 0;
    for (let order_item of basket.items) {
      if (order_item.item_type !== "Delivery") {
        total_price += parseFloat(order_item.price);
      }
    }
    const order_items = basket.items;
    if (channel === "delivery" && !delivery && !newCustomerRef.current) {
      if (typeof window !== "undefined") {
        window.scrollTo(0, 0);
      }
      setOrder_state("channel");

      message = I18nRails.t("venue.alertmessage.select_order_delivery");
      sweetalert2.fire(message);
      return;
    }

    const now = Date.now();
    let date, want_at;
    if (selectedDate !== null) {
      date = selectedDate.valueOf();
    }
    if (now !== date || isFutureOrderActivated() === false) {
      if ((!!deliveryTime && deliveryTime !== null) || deliveryTime !== "") {
        want_at = deliveryTime;
      } else {
        want_at = null;
      }
      if (!!selectedDate) {
        want_at = selectedDate;
      } else {
        want_at = null;
      }
    } else {
      want_at = null;
    }
    if (
      channel === "delivery" &&
      !!address &&
      !!delivery &&
      !!delivery.deliveries &&
      delivery.deliveries.length > 0
    ) {
      let min_delivery_amount = delivery?.deliveries?.[0]?.min_delivery_amount || 0;
      min_delivery_amount = parseFloat(min_delivery_amount);
      if (total_price < min_delivery_amount) {
        message = I18nRails.t("venue.alertmessage.minimum_delivery", {
          min_delivery_amount: min_delivery_amount,
        });
        sweetalert2.fire(message);
        return;
      }

      order = {
        mode_type: channel,
        delivery_address_id: address.id,
        order_items: basket.items,
        token: user_token,
        voucher_code: basket.voucher_code,
        want_at: want_at,
      };
      addDeliveryCharges(order, basket, delivery.deliveries[0]);
    } else {
      order = {
        mode_type: channel,
        order_items: basket.items,
        token: user_token,
        voucher_code: basket.voucher_code,
        want_at: want_at,
      };
      if (channel === "eat_in") {
        order.table_number = "0";
      }
    }
    order.location_id = basket.location_id;
    if (!!basket.location) {
      order.currency = basket.location.currency;
    } else if (!!location0) {
      order.currency = basket.location.currency;
    }

    if (places_payment_method) {
      addPaymentCharges(order, basket, places_payment_method);
      setBasket(basket);
    }

    return order;
  }

  const proceedToCheckout = (basket, order) => {
    setCheckoutStarted(true);

    if (!isLocationOpenNow(fndLocation)) {
      const success = I18nRails.t("venue.alertmessage.closed");
      sweetalert2.fire(`${success} ${locationTimeToday(fndLocation)}.`); //, parent)
      return;
    } else {
      // return false
      if (!proceedToPageCheckout) {
        if (!isLoggedIn(Rails)) {
          //@$state.go('login.location', {location_id: basket.location_id, state: 'location_checkout'})
          window.location.hash = "checkout";
        } else {
          loadUser((user) => {
            if (
              !Rails.require_phone_verification ||
              (user.confirmed_phone && !(user.token_status === "missing"))
            ) {
              //@$state.go('location_checkout', {location_id: basket.location_id})
              processCheckoutState(order, "checkout");
            } else {
              //@$state.go('location_confirm', {location_id: basket.location_id})
              processCheckoutState(order, "confirm");
            }
          });
        }
      } else {
        proceedToPageCheckout();
      }
    }
  };

  const processCheckoutState = (order, order_state) => {
    if (!order_state) {
      order_state = "cart";
      setOrder_state(order_state);
      return;
    }

    if (!isLoggedIn(Rails) && !guestCheckout.current) {
      order_state = "register";
      setOrder_state(order_state);
      return;
    }
    if (
      !channel &&
      !!basket.location &&
      basket.location.channels &&
      basket.location.channels.length > 0
    ) {
      order_state = "channel";

      setOrder_state(order_state);
      return;
    }

    if (
      !!basket.location &&
      (!basket.location.channels || basket.location.channels.length < 1)
    ) {
      //TODO should never happen. Only happens when no channel is provided from backend
      sweetalert2.fire(
        "Location does not provide checkout options at this time. Please check with location"
      );
      order_state = "error";
      setOrder_state(order_state);
      return;
    }
    if (order_state === "address") {
      setOrder_state("payment_options");
      return;
    }

    if (channel === "delivery") {
      setOrder_state("address");
      return;
    }

    if (order_state === "confirm-pin") {
      return;
    }
    //else
    //states can be delivery-time, pin, payments
    if (isFutureOrderActivated()) {
      if (!isOrderAsap) {
        if (!deliveryTime || !dayAhead) {
          order_state = "delivery-time";
          setOrder_state(order_state);
          return;
        }
      }
    }

    if (requirePhone() || requirePinCode()) {
      order_state = "confirm-pin";
      setOrder_state(order_state);
      return;
    }

    if (!paymentSelected) {
      order_state = "payment_options";
      setOrder_state(order_state);
      return;
    }
    //else for all
    order_state = "complete";
    setOrder_state(order_state);
  };

  const proceedToPageCheckout = () => {
    if (!order) {
      setOrder({});
      setOrder_state("cart");
    } else {
      setOrder_state("cart");
    }

    processCheckoutState(order, "cart");
  };

  const gotoCheckoutState = (state_name) => {
      if ((!!order && order_state === "complete") || !checkoutStarted) {
        return;
      }
      if (!isLoggedIn(Rails) && state_name === "channel") {
        return;
      }
      if (
        (state_name === "payment_options" || state_name === "payment") &&
        !checkoutModeSelected
      ) {
        return;
      }

      if (state_name === "payment" && !paymentOptionsSelected) {
        return;
      }
      if (
        state_name === "payment" &&
        !channel &&
        basket.location &&
        basket.location.channels.length > 0
      ) {
      }
      if (
        state_name === "address" ||
        state_name === "cart" ||
        state_name === "channel" ||
        state_name === "payment_options"
      ) {
        setDeliveryResolved(false);
      }

      setOrder_state(state_name);
    }

  const isFutureOrderActivated = () => {
    const futureOrdersActivated =
      !!basket.location &&
      location_future_orders_activated(basket.location) === "1";
    if (
      futureOrdersActivated &&
      deliveryTimes.length < 1 &&
      availableDays.length < 1
    ) {
      const today = new Date();
      const availableDeliveryTimes = availableDeliveryTimes(today);
      setDeliveryTimes(availableDeliveryTimes);
      const availableDays = [];
      const daysAhead = parseInt(basket.location.data.future_orders_days_ahead);
      const dayToday = today.getDate();
      for (
        let day_number = dayToday,
          end = dayToday + daysAhead,
          asc = dayToday <= end;
        asc ? day_number <= end : day_number >= end;
        asc ? day_number++ : day_number--
      ) {
        const tmp_date = new Date(today);
        tmp_date.setDate(tmp_date.getDate() + (day_number - dayToday));
        availableDays.push({ date: tmp_date });
      }
      setAvailableDays(availableDays);
    }
    return futureOrdersActivated && availableDeliveryTimes.length > 0;
  };

  const checkoutDayAhead = (dayAhead) => {
    console.log("day ahead", dayAhead);
    // this.setState(
    //   { dayAhead, deliveryTimes: this.availableDeliveryTimes(dayAhead) },
    //   () => {
    //     this.processCheckoutState(this.state.order, this.state.order_state);
    //   }
    // );
  };

  const checkoutDeliveryTime = (deliveryTime) => {
    setDeliveryTime(deliveryTime);

    processCheckoutState(order, order_state);
  };

  const checkoutOrderAsap = (e) => {
    setIsOrderAsap(e.target.checked);
    processCheckoutState(order, order_state);
  };

  const validatePinCode = () => {
    if (!pinCode || pinCode.length < 4) {
      return;
    }
    if (!!global.validate_token_timeout) {
      clearTimeout(global.validate_token_timeout);
      delete global.validate_token_timeout;
    }
    var token = pinCode;
    const headers = {
      "Time-Zone": Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    if (!!Rails.access_token) {
      headers["Authorization"] = `Bearer ${Rails.access_token}`;
    }

    global.validate_token_timeout = setTimeout(() => {
      axios
        .get(`/users/${user.id}/tokens/valid`, {
          params: { token },
          headers: headers,
        })
        .then(
          (success) => {
            setPinCodeValid(true);
            setOrder_state("payment_options");
            processCheckoutState(order, order_state);
          },
          (error) => {
            setPinCodeValid(false);
            sweetalert2.fire(`${error.response.data.message}`);
          }
        );
    }, 1000);
  };

  const requireEmail = () => {
    return !!guestCheckout.current && !customer_email.current;
  };

  const requirePhone = () => {
    if (!!user) {
      return (
        !user.phone ||
        (!!Rails.require_phone_verification && !user.confirmed_phone)
      );
    } else {
      return !customer_phone;
    }
  };

  const requirePinCode = () => {
    if (!!user) {
      //if phone verification is enabled, then user must have confirmed phone to not require pin
      // or if user has enabled order verification, then pincode must be valid
      //don't require if user.phone is not set YET
      if (!!Rails.require_phone_verification) {
        if (!user.confirmed_phone && !!user.phone && !pinCodeValid) {
          return true;
        }
      }
      if (user.token_status === "set") {
        if (!pinCodeValid) {
          return true;
        }
      }
    } else {
      //for anonymous user, the pinCode if phone verification is required and customer phone is given
      if (!!Rails.require_phone_verification && !!customer_phone && !pinCode) {
        return true;
      }
    }
    return false;
  };

  const onRadioChange = (event, places_payment_method) => {
    const { name, value } = event.target;
    addPaymentCharges(order, basket, places_payment_method);
    setPaymentOptionsSelected(true);
    setRadioValue(places_payment_method);
    setOrder_state("payment");
  };

  function addPaymentCharges(order, basket, places_payment_method) {
    let fee = 0;
    if (places_payment_method.tx_percentage_fee) {
      basket.totals.payment_fee_total ||= 0;
      let payable_amount =
        basket.totals.final_price - basket.totals.payment_fee_total;
      fee +=
        parseFloat(payable_amount) *
        parseFloat(places_payment_method.tx_percentage_fee);
    }
    if (places_payment_method.tx_fee_amount) {
      fee += parseFloat(places_payment_method.tx_fee_amount);
    }
    basket.totals.payment_fee_total = fee;
    basket.places_payment_method = places_payment_method;
    resetBasketTotals(basket, cartService);
    let payment = {
      places_payment_method_id: places_payment_method.id,
      amount: basket.totals.final_price,
    };
    if (!order.payments_attributes) {
      order.payments_attributes = [payment];
    } else {
      order.payments_attributes.push(payment);
    }
  }

  function initPaypal(places_payment_method) {
    // if(typeof window !== "undefined") {
    //   let PAYPAL_SCRIPT = `https://www.paypal.com/sdk/js?client-id=${this.state.basket.location.paypal_client_id}`;
    //   let script = document.createElement('script');
    //   script.setAttribute('src', PAYPAL_SCRIPT);
    //   document.head.appendChild(script);
    // }
    // this.PayPalButton = paypal.Buttons.driver("react", { React, ReactDOM });
    paypalProps.current = {
      options: {
        clientId:
          basket.location.paypal_client_id ||
          "AQeqjH37YzshOkOYa2GneMHbRJfZ6p3GHBnF3JYNnF_7hdE4mZdFlKqCNpgqHumr34ZnQe-Bfz_70Rdh",
      },
      //env: Rails.paypal_mode
      //client:
      //  sandbox: Rails.paypal_client
      //  production: Rails.paypal_client
      createOrder: (data, actions) => {
        const newOrder = generateOrder(
          basket,
          user.token,
          places_payment_method,
          channel,
          delivery,
          selectedAddress
        );
        // if (!!newOrder) {
        return actions.order.create({
          purchase_units: [
            {
              amount: {
                // value: newOrder.payments_attributes[0].amount/100,
                value: newOrder.payments_attributes[0].amount,
                // currency: "USD",
                currency: newOrder.currency,
              },
            },
          ],
        });
        // }
      },
      onApprove: (data, actions) => {
        setOrderProcessing(true);
        const newOrder = generateOrder(
          basket,
          pinCode,
          places_payment_method,
          channel,
          delivery,
          selectedAddress
        );
        // if (!!newOrder) {
        return actions.order.capture().then(function (details) {
          if (!newOrder.payments_attributes) {
            newOrder.payments_attributes = [];
          }

          //attach paypal authorization with order
          newOrder.payments_attributes.push({
            type: "Payment::Paypal",
            places_payment_method_id: places_payment_method.id,
            transaction_log: data,
          });
          const UserOrder = UserOrderResource.get(me.Rails);
          UserOrder.create({ order: newOrder }, (order) => {
            setOrderProcessing(false);

            cartService.setOrder(order);
            navigate(rh.show_order(order));
          });
        });
        // }
      },
    };
  }

  function initSupportedDeliveryPayments(location) {
    if (!!location.available_places_payment_methods) {
      let paypalIdx = location.available_places_payment_methods.findIndex(
        (places_payment_method) => places_payment_method.admin_name == "paypal"
      );
      if (paypalIdx > -1) {
        initPaypal(location.available_places_payment_methods[paypalIdx]);
      }
    }
  }

  const handleClose = () => {
    setOpenAddressDialog(false);
  };

  const proceedAsGuest = () => {
    setOrder_state("channel");
    setEnableGuestCheckout(true);
  };

  const validateHandler = (values) => {
    const errors = {};
    if (!values.guestEmail) {
      errors.guestEmail = "Email required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.guestEmail)
    ) {
      errors.guestEmail = I18nRails.t(
        "client.profile.login.invalid_email_address"
      );
    }

    if (
      values.hasOwnProperty("guestnewConfirmpassword") &&
      values.hasOwnProperty("guestnewpassword")
    ) {
      if (!values.guestnewpassword) {
        errors.guestnewpassword = "password required";
      } else if (values.guestnewpassword.length < 6) {
        errors.guestnewpassword = "password should be more tan 6 characters";
      }

      if (!values.guestnewConfirmpassword) {
        errors.guestnewConfirmpassword = "please confirm the password";
      } else if (values.guestnewConfirmpassword) {
        if (values.guestnewConfirmpassword !== values.guestnewpassword) {
          errors.guestnewConfirmpassword = errors.guestnewpassword =
            "Password does not match";
        }
      }
    }

    if (!values.guestName) {
      errors.guestName = "Name required";
    }
    if (!values.guestPhone || !/^(\+|00)92\d{10}$/.test(values.guestPhone)) {
      errors.guestPhone = "Format: +92xxxxxxxxxx";
    }
    return errors;
  };

  const addVerification = (e) => {
    setUserInputVerification(e);
  };

  const verifyOrder = (e) => {
    setTimeout(() => {
      setEnableGuestCheckout(false);
      setOpenAddressDialog(false);
      placeOrder();
    }, 1000);
  };

  const placeOrder = () => {
    var customer_id = newCustomerRef.current.id;
    var customer_token = userInputVerification;
    var places_payment_method = radioValue;
    //@Location.time_status { id: @state.basket.location.id }, (location) =>
    const foodnerdLocation = new Location.current(basket.location);
    setOpenAddressDialog(false);
    if (!isLocationOpenNow(foodnerdLocation)) {
      const success = I18nRails.t("venue.alertmessage.closed");
      sweetalert2.fire(`${success} ${locationTimeToday(foodnerdLocation)}.`); //, parent)
      return;
    } else {
      if (requirePinCode() && !newCustomerRef.current) {
        if (!pinCode) {
          sweetalert2.fire(I18nRails.t("venue.alertmessage.entertoken"));
          return;
        } else if (pinCode.length !== 4) {
          sweetalert2.fire(I18nRails.t("venue.alertmessage.tokenelse"));
          return;
        }
      }

      if (requirePhone() && !newCustomerRef.current) {
        sweetalert2.fire(I18nRails.t("venue.alertmessage.enterphone"));
        return;
      }

      if (!basket.items.length > 0) {
        sweetalert2.fire(I18nRails.t("venue.alertmessage.emptycart"));
        return;
      }

      if (!radioValue) {
        setOrder_state("payment_options");
        sweetalert2.fire(
          I18nRails.t("venue.alertmessage.select_payment_method")
        );
        return;
      }
      if (!channel) {
        setOrder_state("channel");
        sweetalert2.fire(I18nRails.t("venue.alertmessage.select_order_mode"));
        return;
      }
      if (enableGuestCheckout) {
        setOpenAddressDialog(true);
        setShowVerification(true);
        return;
      }
      //@order.applyPayment(paymentMethod, paymentAnnotation)
      //TODO should upgrade code to change order from state to state, instead of
      // relying on generateOrder to apply all the business logic
      const order = generateOrder(
        basket,
        pinCode,
        places_payment_method,
        channel,
        delivery,
        selectedAddress
      );

      if (!!order) {
        if (!!customer_id) {
          (order.customer_id = customer_id),
            (order.customer_attributes = {
              id: customer_id,
              verification_code: customer_token,

              addresses_attributes: [
                {
                  ...guestAddress,
                  country: "PK",
                  owner_type: "customer",
                },
              ],
            });
          console.log("sending address", guestAddress);
        }

        setOrder(order);
        setOrderProcessing(true);
        const UserOrder = UserOrderResource.get(Rails);

        UserOrder.create(
          { order },
          (order) => {
            if (!!order.error) {
              showResourceResponseError.current(error);
              setOrderProcessing(false);
              return;
            }
            if (
              order.payment_state == "payment_entered" &&
              order.payment_status == "ccard_entered"
            ) {
              window.location.href = `/orders/${order.number}/payments/${
                order.payments[order.payments.length - 1].id
              }/make`;
            } else if (
              order.state === "received" ||
              order.state === "accepted" ||
              order.state == "completed"
            ) {
              //@CartService.setOrder(order)
              clearCart(basket);
              cartService.setOrder(order);
              window.location.href = rh.show_order(order);
            } else {
              cartService.setOrder(order);
              navigate(rh.show_order(order));
            }
            setOrder(order);
            setOrderProcessing(false);
          },
          (error) => {
            // TODO: if address error then this.setState({order_state: "address"});
            showResourceErrorCheckout(error);
            setOrderProcessing(false);
            setUserInputVerification("");
            setShowVerification(true);
          }
        );
      }
    }
  };

  function showResourceErrorCheckout(error) {
    if (!!error.response) {
      error.response.json().then(response => {
        console.log("response is", response);
        showResourceResponseErrorCheckout(response);  
      })
    } else {
      sweetalert2.fire(error.message);
    }
  }

  function showResourceResponseErrorCheckout(response) {
    if (!!response.errors) {
      sweetalert2.fire(response.errors.join("\n"));
    } else if (!!response.full_messages) {
      if (response.messages.hasOwnProperty("customer_id")) {
        setShowVerification(true);
      }
      if (response.messages.hasOwnProperty("order_items.item")) {
        setOrder_state("address");
      }
      if (
        response.full_messages.indexOf("You entered incorrect pin code") !== -1
      ) {
        setOpenAddressDialog(true);
        setShowVerification(true);
        setInvalidVerificationCodeError(true);
        return;
      }
      sweetalert2.fire(response.full_messages);
    } else {
      const errors = [];
      for (let key in response) {
        const field_errors = response[key];
        for (let field_error of field_errors) {
          errors.push(`${key} ${field_error}`);
        }
      }
      sweetalert2.fire(errors.join("\n"));
    }
  }

  const selectChannel = (channel) => {
    setCheckoutModeSelected(true);
    if (enableGuestCheckout) {
      setOpenAddressDialog(true);
      setOpenGuestRegisterPasswordDialog(false);
      setChannel(channel);

      return;
    }
    const isOrderAsap = !isFutureOrderActivated();

    let updatedSelectedAddress;
    if (user) {
      const idx = user.addresses.findIndex(
        (addrss) => addrss.default_address === true
      );

      if (idx >= 0) {
        updatedSelectedAddress = user.addresses[idx];
      }
    } else {
      updatedSelectedAddress = selectedAddress;
    }
    // @selectAddress(@state.user.addresses[0], true)
    const updatedBasket = { ...basket };
    updatedBasket.totals.delivery_charges = 0;
    resetBasketTotals(updatedBasket, cartService);
    setBasket(updatedBasket);
    setSelectedAddress(updatedSelectedAddress);
    setChannel(channel);
    setDeliveryTime(null);
    setDayAhead(null);
    setIsOrderAsap(isOrderAsap);
    if (
      channel === "delivery" &&
      !selectedAddress &&
      !!user &&
      !!user.addresses &&
      user.addresses.length > 0
    ) {
      processCheckoutState(order, "channel");
    } else {
      processCheckoutState(order, order_state);
    }
  };

  const cancelChannel = () => {
    setChannel("");
    setSelectedAddress("");
    setDeliveryResolved(false);
    processCheckoutState(order, order_state);
  };

  const showConfirm = (message, onOk, onCancel) => {
    // Appending dialog to document.body to cover sidenav in docs app
    if (confirm(message)) {
      if (!!onOk) {
        return onOk();
      }
    } else {
      if (!!onCacnel) {
        return onCancel();
      }
    }
  };

  const isOpenAt0 = (d) => {
    OnTimesParser.create(basket.location.ontimes);
    return OnTimesParser.isOnAt(d);
  };

  const availableDeliveryTimes = (timestamp) => {
    let firstTime;
    if (!timestamp) {
      return;
    }
    const futureOrdersConfig =
      !!basket.location &&
      basket.location.data &&
      basket.location.data["future_orders"];
    if (!futureOrdersConfig) {
      return [];
    }
    if (!futureOrdersConfig.future_orders_activated) {
      return [];
    }
    const onTimes = OnTimesParser.create(futureOrdersConfig.ontimes);
    const minimumAhead = parseInt(
      futureOrdersConfig.future_orders_minimum_ahead
    );
    const interval = parseInt(futureOrdersConfig.future_orders_interval);

    const workingRange = OnTimesParser.closestOnRange(timestamp);
    if (!workingRange) {
      return [];
    }
    if (workingRange.start == null) {
      return [];
    }
    if (isOpenAt(timestamp)) {
      firstTime = new Date(
        workingRange.start.setMinutes(
          workingRange.start.getMinutes() + minimumAhead
        )
      );
    } else {
      //# openingAhead
      firstTime = new Date(
        workingRange.start.setMinutes(workingRange.start.getMinutes() + 30)
      );
    }

    timestamp.setMinutes(timestamp.getMinutes() + minimumAhead);
    if (firstTime < timestamp) {
      firstTime.setHours(timestamp.getHours());
      if (firstTime < timestamp) {
        const intervalsDiff = Math.ceil(
          Math.abs(firstTime.getMinutes() - timestamp.getMinutes()) / interval
        );
        firstTime.setMinutes(firstTime.getMinutes() + intervalsDiff * interval);
      }
    }

    const lastTime = workingRange.end;

    const deliveryTimes = [];
    const timeCursor = new Date(firstTime);
    while (timeCursor <= lastTime) {
      deliveryTimes.push({ time: new Date(timeCursor) });
      timeCursor.setMinutes(timeCursor.getMinutes() + interval);
    }
    if (
      deliveryTimes.length === 0 &&
      (workingRange != null ? workingRange.end : undefined) != null
    ) {
      //avoid twilight zone
      return availableDeliveryTimes(new Date(timestamp.getMinutes() + 60000));
    }
    return deliveryTimes;
  };

  const onChangePhoneNumberEvent = (e) => {
    setPhone_number(e.target.value);
  };

  const onChangePinEvent = (e) => {
    // console.log('on change pin event', e.target.value)
    if (pinCode !== e.target.value) {
      setPinCode(e.target.value);
    }
  };

  const User = useRef(UserResource.get(Rails));
  const AuthFactory0 = useRef(new AuthFactory(Rails));
  const AccessToken = useRef(new AccessTokenFactory(Rails));
  const Deliveries = useRef(DeliveriesResource.get(Rails));
  const newCustomerRef = useRef({});
  const guestCheckout = useRef(undefined);
  const customer_email = useRef(undefined);
  const paypalProps = useRef(undefined);
  const customerAddresess = useRef(undefined);
  const Location = useRef(LocationResource.get(Rails));

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.scrollTo(0, 0);
    }

    const order = generateOrder(
      basket,
      pinCode,
      null,
      channel,
      delivery,
      selectedAddress
    );
    setOrder(order);

    if (
      !!basket &&
      !!basket.location &&
      basket.location.channels &&
      basket.location.channels.length < 1
    ) {
      setErrorMessage(
        I18nRails.t("venue.contact.error", {
          location_name: basket.location.name,
          phone: basket.location.phone,
        })
      );
    }
    if (!!basket && !!basket.location) {
      initSupportedDeliveryPayments(basket.location);
    }

    processCheckoutState(order, order_state);
    loadLocation((location) => {
      initSupportedDeliveryPayments(basket.location);
      setBasket({ ...basket, location: location });
      setLocation(location);
      setLocation_id(location.slug);
    });
  }, []);

  let location;
  if (basket.location) {
    location = LocationResource.attachMethods(basket.location);
  }

  resetBasketTotals(basket, cartService);
  return (
    <AppLayout basket={basket} Rails={Rails} user={user} {...other}>
      {!!location &&
        (!!location_main_image_url(location) ? (
          <div
            className="dashboard-bg-img"
            style={{
              backgroundImage: "url(" + location_main_image_url(location) + ")",
            }}
          />
        ) : (
          <div
            className="dashboard-bg-img"
            style={{ backgroundImage: "url(" + orderNowBackground + ")" }}
          />
        ))}
      <div className="container" style={{ marginTop: "20px" }}>
        <div className="row">
          <section id="content">
            <h3>
              {basket.location && (
                <Link
                  to={"/locations/" + basket.location_id}
                  className="favorite-places black-text"
                >
                  <i className="material-icons">arrow_back_ios</i>
                  {basket.location.name}
                </Link>
              )}
            </h3>
          </section>
        </div>
        <Container>
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ marginBottom: "70px" }}
          >
            <Grid size={{xs: 12}}>
              <CheckoutSteps
                order={order}
                Rails={Rails}
                user={user}
                errorMessage={errorMessage}
                order_state={order_state}
                gotoCheckoutState={gotoCheckoutState}
                basket={basket}
                channel={channel}
                requirePinCode={requirePinCode}
                requirePhone={requirePhone}
                selectChannel={selectChannel}
                selectedDate={selectedDate}
                handleDateChange={handleDateChange}
                setBasketField={setBasketField}
                verifyVoucherCode={verifyVoucherCode}
                changeQuantity={changeQuantity}
                clearCart={clearCart}
                proceedToCheckout={proceedToCheckout}
                showVerification={showVerification}
                openGuestRegisterPasswordDialog={
                  openGuestRegisterPasswordDialog
                }
                addressError={addressError}
                showCreatedUserAlert={showCreatedUserAlert}
                handleClose={handleClose}
                userInputVerification={userInputVerification}
                addVerification={addVerification}
                invalidVerificationCodeError={invalidVerificationCodeError}
                verifyOrder={verifyOrder}
                onChangePhoneNumberEvent={onChangePhoneNumberEvent}
                phone_number={phone_number}
                confirmPhoneNumber={confirmPhoneNumber}
                onChangePinEvent={onChangePinEvent}
                pin_code={pinCode}
                validatePinCode={validatePinCode}
                setUserPhone={setUserPhone}
                radioValue={radioValue}
                orderProcessing={orderProcessing}
                paypalProps={paypalProps}
                placeOrder={placeOrder}
                onRadioChange={onRadioChange}
                cancelChannel={cancelChannel}
                selectedAddress={selectedAddress}
                proceedAsGuest={proceedAsGuest}
              />
            </Grid>
          </Grid>
        </Container>
      </div>
    </AppLayout>
  );
};

export default withParent(withOrder(withCart(AppLocationCart)));
