import React from "react";
import { Link, useLocation } from "react-router";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Grid from "@mui/material/Grid";
import ReactCodeInput from "react-code-input";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { I18nRails } from "../../../shared/rails-i18n-js";
import { loadImage } from "../helpers/utils";

const template = ({
  showVerification,
  openGuestRegisterPasswordDialog,
  channel,
  showAddressError,
  addressError,
  showCreatedUserAlert,
  handleClose,
  userInputVerification,
  addVerification,
  invalidVerificationCodeError,
  verifyOrder,
  createCustomer,
  proceedAsGuest,
  openAddressDialog,
  validateHandler,
}) => {
  const domLocation = useLocation();
  const children = ({ isSubmitting, values, handleChange, isValidating }) => {
    return (<>
      {!showVerification ? (
        <Form method="post" className="personal-form login-form">
          {openGuestRegisterPasswordDialog ? (
            <>
              <DialogTitle>Password information</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Please provide the required information
                  <Grid container>
                    <Field
                      className="browser-default form-control"
                      onChange={handleChange}
                      name="guestnewpassword"
                      placeholder="Enter password "
                      required
                      type="password"
                    />
                    <ErrorMessage
                      name="guestnewpassword"
                      component="validation-message"
                    />
                    <Field
                      className="browser-default form-control"
                      onChange={handleChange}
                      name="guestnewConfirmpassword"
                      placeholder="Confirm password "
                      required
                      type="password"
                    />
                    <ErrorMessage
                      name="guestnewConfirmpassword"
                      component="validation-message"
                    />
                  </Grid>
                </DialogContentText>
              </DialogContent>
            </>
          ) : (
            <>
              <DialogTitle>Contact information</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Please provide the required information
                  <Grid container>
                    <Grid size={12}>
                      <Field
                        className="browser-default form-control"
                        onChange={handleChange}
                        name="guestName"
                        placeholder="Name of receiver "
                        required
                        type="text"
                      />
                      <ErrorMessage
                        name="guestName"
                        component="validation-message"
                      />
                      {channel === "delivery" && (
                        <>
                          <Field
                            className="browser-default form-control"
                            onChange={handleChange}
                            name="guestStreet1"
                            placeholder="Street 1"
                            required
                            type="text"
                          />
                          <Field
                            className="browser-default form-control"
                            onChange={handleChange}
                            name="guestStreet2"
                            placeholder="Street 2"
                            required
                            type="text"
                          />
                          <Field
                            className="browser-default form-control"
                            onChange={handleChange}
                            name="guestCity"
                            placeholder="City eg: Islamabad"
                            required
                            type="text"
                          />
                          <Field
                            className="browser-default form-control"
                            onChange={handleChange}
                            name="guestZip"
                            placeholder="Zip e.g 44000"
                            required
                            type="number"
                          />
                        </>
                      )}
                      <Field
                        className="browser-default form-control"
                        onChange={handleChange}
                        name="guestPhone"
                        placeholder="Format: +92xx-xxxxxxx"
                        required
                        type="text"
                      />
                      <ErrorMessage
                        name="guestPhone"
                        component="validation-message"
                      />
                      <Field
                        className="browser-default form-control"
                        onChange={handleChange}
                        name="guestEmail"
                        placeholder="Email"
                        required
                        type="text"
                      />
                      <ErrorMessage
                        name="guestEmail"
                        component="validation-message"
                      />
                      <Grid container style={{ padding: "2%" }}>
                        <Grid size={6}>
                          <Field
                            className="form-control"
                            onChange={handleChange}
                            name="guestWhatsapp"
                            type="checkbox"
                          />
                          <label style={{ paddingLeft: "5px" }}>
                            Enable WhatsApp messages?
                          </label>
                        </Grid>
                        {channel === "delivery" && (
                          <Grid size={6}>
                            <Field
                              className="form-control"
                              onChange={handleChange}
                              name="guestRegister"
                              type="checkbox"
                            />
                            <label style={{ paddingLeft: "5px" }}>
                              Get Registered?
                            </label>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </DialogContentText>
                {channel === "delivery" && showAddressError && (
                  <Alert severity="error">{addressError}</Alert>
                )}
              </DialogContent>
            </>
          )}
          {showCreatedUserAlert && (
            <Alert severity="success" style={{ marginTop: "3%" }}>
              <AlertTitle>4-digit pin sent!</AlertTitle>
              You will be required to use this pin later.
            </Alert>
          )}
          <DialogActions>
            <Button className="btn-red" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              className="btn-second"
              type="submit"
              disabled={isSubmitting || isValidating}
              onClick={createCustomer}
            >
              Submit
            </Button>
          </DialogActions>
        </Form>
      ) : (
        <>
          <DialogTitle>Verification Code</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Check your phone and enter the 4-digit code for placing order.
            </DialogContentText>
            <div style={{ marginLeft: "25%", padding: "15px" }}>
              <ReactCodeInput
                type="number"
                value={userInputVerification}
                onChange={addVerification}
                inputMode="numeric"
                fields={4}
              />
            </div>
            {invalidVerificationCodeError && (
              <Alert severity="error">Invalid pin — recheck!</Alert>
            )}
          </DialogContent>
          <DialogActions>
            <Button className="btn-red" onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button className="btn-second" onClick={verifyOrder}>
              Place Order
            </Button>
          </DialogActions>
        </>
      )}
    </>);
  };

  return (<>
    <Grid container spacing={4}>
      <Grid size={12}>
        <p>{I18nRails.t("Web.your_order")}</p>
      </Grid>
      <Grid size={2}>
        <Button
          style={{ whiteSpace: "normal" }}
          className="clr-dark"
          onClick={() => proceedAsGuest()}
        >
          {I18nRails.t("Web.proceed_guest_checkout")}
        </Button>
      </Grid>
      <Grid size={2}>
        <Link
          to={`/login?return_url=${domLocation.pathname}${domLocation.search}`}
        >
          <Button className="btnLoginCart btn-first">
            {I18nRails.t("Web.proceed_as_login")}
          </Button>
        </Link>
      </Grid>
      <Grid size={5}>
        <img className="responsive-img" alt="" src={loadImage("food.png")} />
      </Grid>
    </Grid>
    <Dialog open={openAddressDialog} onClose={handleClose}>
      <Formik
        onSubmit={createCustomer}
        initialValues={{
          guestStreet1: "",
          guestStreet2: "",
          guestCity: "",
          guestZip: "",
        }}
        validate={validateHandler}
      >
        {children}
      </Formik>
    </Dialog>
  </>);
};

export default template;
