import React from "react";
import { I18nRails } from "../../../shared/rails-i18n-js";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import { isLoggedIn } from "../helpers/utils";
import { isUserLiked } from "../../models/user";
import { isLocationOnlineOrdersActive, locationHasDelivery } from "../../models/location";

export default function SubHeader({
  Rails,
  user,
  initUser,
  fndLocation,
  toggleLike
}) {
  if (!user) {
    user = initUser();
  } else {
  }

  return (
    (<Grid container spacing={2} sx={{ display:'flex' , justifyContent:'center' , marginTop:'10px' , marginBottom:'10px' }}>
      {/* <Grid size={3}>
        <Box border={1} borderColor="#e0e0e0" style={{ height: '11vh', alignContent: 'center' }}>
          <p>
            <a style={{ color: 'grey' }} href={`tel:${fndLocation.phone}`}>
              <i className="material-icons center subHeadIcons phone"></i>
              {fndLocation.phone}
            </a>
          </p>
        </Box>
      </Grid> */}
      <Grid size={3} sx={{display: "flex"}}>
        {isLocationOnlineOrdersActive(fndLocation) && (
          <Box borderColor="#e0e0e0" border={1} sx={{ flexGrow: 1, textAlign: 'center', alignContent: 'center' }}>
            <i className="material-icons center subHeadIcons flash_on"></i>
            {I18nRails.t('Search.filters.online_orders')}
          </Box>
        )}
      </Grid>
      {locationHasDelivery(fndLocation) && (
      <Grid size={3} sx={{display: "flex"}}>
          <Box borderColor="#e0e0e0" border={1} sx={{ flexGrow: 1, textAlign: 'center', alignContent: 'center' }}>
            <i className="material-icons center subHeadIcons directions_car"></i>
            {I18nRails.t('Search.filters.deliveries')}
          </Box>
      </Grid>
        )}
      <Grid size={3} sx={{display: "flex"}}>
        <Box borderColor="#e0e0e0" border={1} sx={{ flexGrow: 1,textAlign: 'center', alignContent: 'center' }}>
          {isLoggedIn(Rails) && (
            <a className="heart-icon-white" onClick={(e) => toggleLike(fndLocation, "Location", e)}>
              <i className={`fas fa-heart${user && isUserLiked(user, fndLocation, "Location") ? " red-text" : ""}`}></i>
              {` ${fndLocation.favorites_count}`}
            </a>
          )}
          {!isLoggedIn(Rails) && (
            <a className="heart-icon-white" onClick={(e) => toggleLike(fndLocation, "Location", e)}>
              <i className="grey-text fas fa-heart"></i>
              {` ${fndLocation.favorites_count}`}
            </a>
          )}
          {!isLoggedIn(Rails) && <span>&nbsp;Likes</span>}
        </Box>
      </Grid>
    </Grid>)
  );  
};
