/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import React from 'react';
import { GoogleMapProvider, MapBox, Marker } from '@googlemap-react/core';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { I18nRails } from '../../../shared/rails-i18n-js';
import { locationOperationHours, location_address } from '../../models/location';

const template = ({
  fndLocation,
  viewHours,
  closeHours,
  setLatLng,
  unmappedAddress
}) => {
  var address = location_address(fndLocation);
  var operationHours = locationOperationHours(fndLocation);
  return (
    (<Dialog open={viewHours} fullWidth={true} maxWidth='xl'>
      <DialogTitle style={{ paddingBottom: 0, paddingTop: 3, paddingRight: 0 }}>
        <Grid container>
          <Grid size={11} />
          <Grid style={{ textAlign: 'right' }} size={1}>
            <IconButton aria-label="close" onClick={(e) => closeHours()}>
              <CloseIcon style={{ color: '#ef757f' }} />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent style={{ paddingTop: 0 }}>
        <Grid container>
          {fndLocation && (
            <Grid size={6}>
              <h4 className="first-color subhead-font" style={{ marginBottom: 1 }}>
                {fndLocation.name}
              </h4>
              {address ? (
                <div style={{ height: '400px', padding: '4%' }}>
                  <GoogleMapProvider>
                    <MapBox
                      apiKey={process.env.GOOGLE_GEOCODE_API_KEY}
                      opts={{ center: { lat: address.lat, lng: address.lng }, zoom: 12 }}
                      onClick={(e) => setLatLng(e, unmappedAddress)}
                    />
                    <Marker
                      opts={{ label: fndLocation.name, draggable: false, position: { lat: address.lat, lng: address.lng } }}
                      onDragend={(e) => setLatLng(e, unmappedAddress)}
                    />
                  </GoogleMapProvider>
                </div>
              ) : (
                <CircularProgress />
              )}
            </Grid>
          )}
          {fndLocation && (
            <Grid size={6}>
              <p>{fndLocation.description}</p>
              <p className="first-color subhead-font">Address</p>
              {fndLocation.addresses && (
                <div>
                  <p>{address.full_address}</p>
                </div>
              )}
              <Grid container>
                <Grid size={12}>
                  <p className="first-color subhead-font">{I18nRails.t('Search.LocationShow.openinghours')}</p>
                  {operationHours.map((hours_of_operation, index) => (
                    <div key={'oh'+index}>
                      <Grid container>
                        <Grid style={{ padding: '0' }} size={4}>
                          {hours_of_operation.split('day')[0]}day
                        </Grid>
                        <Grid size={8}>{hours_of_operation.split('day')[1]}</Grid>
                      </Grid>
                    </div>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>)
  );  
};

export default template;
